import { Routes as CoreRoutes } from 'core/pages'
import InboundConveyor from './InboundConveyor/InboundConveyor'
import InboundWorkstation from './InboundWorkstation/InboundWorkstation'
import MassiveConveyor from './MassiveConveyor/MassiveConveyor'
import OutboundConveyor from './OutboundConveyor/OutboundConveyor'
import PrintWamScreen from './PrintWam/PrintWam'

///////////////////////////////////////////////////////////////////////////////
// Custom & core overrides, change only this!
///////////////////////////////////////////////////////////////////////////////

/*
const CustomRoutes = {
  '/simple-item-list/:opCode/reading': { component: SimpleItemListReading },
  '/simple-parcel-list/:opCode': { component: SimpleParcelListStart },
}
*/

const CustomDirectRoutes = {}
const CustomRoutes = {
  '/inbound-conveyor/': { component: InboundConveyor },
  '/inbound-workstation/': { component: InboundWorkstation },
  '/massive-conveyor/': { component: MassiveConveyor },
  '/outbound-conveyor/': { component: OutboundConveyor },
  '/print-wam': { component: PrintWamScreen },
}

///////////////////////////////////////////////////////////////////////////////

export const Routes = { ...CustomDirectRoutes, ...CoreRoutes, ...CustomRoutes }
// eslint-disable-next-line
export type Routes = keyof typeof Routes
