import { AppConfiguration } from 'stylewhere/types'

export const config: AppConfiguration = {
  //temera dev
  // endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.stylewhere-dev.temera.it/',
  // keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'temera',
  //login: admin / temera!2021

  //cucinelli dev
  //endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.cucinelli-stw-dev.temera.it/',
  //keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'cucinelli-dev',
  //login: admintemera / Temera2022!

  //cucinelli quality
  //endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.cucinelli-stw-qty.temera.it/',
  //keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'cucinelli',
  //login daniele.ceci@temera.it / 20231005.dc

  //cucinelli produzione
  //endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.cucinelli-stw.temera.it/',
  //keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'cucinelli',
  //keycloakUrl: 'https://auth.temera.it/',
  //login augusto.semplicini@temera.it / 20231002.as

  //valentino dev
  endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.valentino-stw-qty.temera.it/',
  keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'valentino-qty',
  //login admin / Temera2022!

  //valentino quality
  //endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.valentino-stw-qty.temera.it/',
  //keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'valentino-qty',
  //login admintemera / Temera2023!

  //moncler dev
  //endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.moncler-stw-dev.temera.it/',
  //keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'moncler-dev',
  //login admintemera / Temera2023!

  keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL ?? 'https://sso-dev.temera.it/',
  keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'stw-core-station',

  //datadog
  dataDogApplicationID: process.env.REACT_APP_DATA_DOG_APPLICATION_ID ?? '', //'33ec3ee8-9890-4c70-841b-78e47a5f0784',
  dataDogClientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN ?? '', //'pub2402a745b62de001db8688519c7c2cdc',
  dataDogSite: process.env.REACT_APP_DATA_DOG_SITE ?? '', //'datadoghq.eu',
  dataDogService: process.env.REACT_APP_DATA_DOG_SERVICE ?? '', //'stw-rfid-station-dev',
  dataDogEnv: process.env.REACT_APP_DATA_DOG_ENV ?? '', //'',
  dataDogSessionSampleRate: process.env.REACT_APP_DATA_DOG_SITE_SESSION_SAMPLE_RATE
    ? parseInt(process.env.REACT_APP_DATA_DOG_SITE_SESSION_SAMPLE_RATE, 10)
    : 100,
  dataDogSessionReplaySampleRate: process.env.REACT_APP_DATA_DOG_SITE_SESSION_REPLAY_SAMPLE_RATE
    ? parseInt(process.env.REACT_APP_DATA_DOG_SITE_SESSION_REPLAY_SAMPLE_RATE, 10)
    : 20,
  dataDogTrackResources:
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_RESOURCES &&
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_RESOURCES === 'true'
      ? true
      : true,
  dataDogTrackLongTasks:
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_LONG_TASKS &&
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_LONG_TASKS === 'true'
      ? true
      : false,
  dataDogTrackUserInteractions:
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_USER_INTERACTIONS &&
    process.env.REACT_APP_DATA_DOG_SITE_TRACK_USER_INTERACTIONS === 'true'
      ? true
      : true,
}
