import { FormSchemaData, OperationConfig } from 'stylewhere/shared'
import { __, T } from 'stylewhere/i18n'
import { askUserConfirmation } from 'stylewhere/shared/utils'
import { Extensions } from './Extensions'

export class SimpleItemListExtensions extends Extensions {
  static async beforeConfirm<O extends any[]>(
    operation: OperationConfig,
    data: FormSchemaData,
    // Possono essere una lista di items, di parcels o altro a seconda del tipo di operazione
    objects: O
  ): Promise<boolean> {
    const warningItems = objects.filter((itm) => itm.item?.status === 'warning').length
    if (warningItems > 0) {
      const result = await askUserConfirmation(
        __(T.messages.warning_items_found),
        __(T.messages.are_you_sure_you_want_to_confirm)
      )
      if (!result) throw new Error(undefined)
    }
    return super.beforeConfirm(operation, data, objects)
  }
}
