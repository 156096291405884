import styled from '@emotion/styled'
import { Box, Collapse, IconButton, useDisclosure } from '@chakra-ui/react'
import { Icons } from 'stylewhere/components'

interface Props {
  title?: string
  children: any
  defaultIsClosed?: boolean
}

export default function CollapsibleSection({ title, defaultIsClosed, children }: Props) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: !defaultIsClosed })

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {title && <Title>{title}</Title>}
        <Box onClick={onToggle} cursor="pointer" style={{ padding: 20 }}>
          {isOpen ? <Icons.ArrowUpBold /> : <Icons.ArrowDownBold />}
        </Box>
      </Box>
      <Collapse in={isOpen}>{children}</Collapse>
    </Box>
  )
}

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`
