import { Grid } from '@chakra-ui/react'
import { Box, FormSchemaTextField, Modal } from 'stylewhere/components'
import { T, __ } from 'stylewhere/shared/i18n'

interface Props {
  visible: boolean
  onClose: () => void
  onMeasureChange: (key: string, value: string) => void
  allMeasuresList: {
    key: string
    label: string
    type: string
  }[]
  measurements: { [key: string]: string }
}

export default function InboundWorkstationMeasurementsModal({
  allMeasuresList,
  onClose,
  onMeasureChange,
  visible,
  measurements,
}: Props) {
  return (
    <Modal onClose={onClose} visible={visible} size={'6xl'}>
      <Box center>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          {allMeasuresList?.map((measure, index) => (
            <FormSchemaTextField
              defaultValue={undefined}
              field={{ type: 'text', label: measure.label, name: 'measurement' + index, focus: false }}
              index={9}
              value={measurements[measure.key]}
              onChange={(text: string) => {
                onMeasureChange(measure.key, text)
              }}
            />
          ))}
        </Grid>
      </Box>
    </Modal>
  )
}
