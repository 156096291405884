// import { AppConfiguration } from 'stylewhere/types'

export * from 'core/config'

// export const config: AppConfiguration = {
//   endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.cucinelli-stw.temera.it/',
//   keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL ?? 'https://auth.temera.it/',
//   keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'cucinelli',
//   keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'stw-core-station',
// }

export const merchCategories = ['abbigliamento', 'maglieria', 'accessori', 'calzature', 'pelletteria']
