import { T, __ } from 'stylewhere/shared/i18n'
import { InfoContainer } from './InfoContainer'
import { Box, Button, FormSchemaTextField, InboundWorkstationNoteModal, Spacer } from 'stylewhere/components'
import React, { useRef, useState } from 'react'
import { NoteModalRow } from './InboundWorkstationNoteModal'
import { askUserConfirmation, showToast, showToastError } from 'stylewhere/shared/utils'
import { InboundWorkstationApi, api } from 'stylewhere/api'
import { InboundWorkstationReportData, SapInbound } from 'stylewhere/api/types'
import { format } from 'date-fns'
import { AppStore } from 'stylewhere/shared'
import { config } from 'stylewhere/config'

interface Props {
  missionCode?: string
  warehouse?: string
  style?: React.CSSProperties
  data?: InboundWorkstationReportData
  merchCategory?: string
  setShowPageOverlayLoader: (show: boolean) => void
  onConfirmationDone?: () => void
  onRowPartialConfirmationDone: (row: NoteModalRow) => Promise<void>
  refreshWorkstationData: () => Promise<void>
}

export function InboundWorkstationRightForm({
  missionCode,
  style,
  data,
  warehouse,
  merchCategory,
  setShowPageOverlayLoader,
  onConfirmationDone,
  onRowPartialConfirmationDone,
  refreshWorkstationData,
}: Props) {
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false)
  const [noteModalRows, setNoteModalRows] = useState<SapInbound[]>([])
  const [loading, setLoading] = useState(false)

  const evtSource = useRef<EventSource | null>(null)

  const openNotesModal = async () => {
    try {
      if (!missionCode || !warehouse) throw new Error('Codice missione o warehouse non trovato')
      setLoading(true)
      const res = (await InboundWorkstationApi.getSapInbounds(missionCode, warehouse)).content
      setNoteModalRows(res)
      setIsNoteModalVisible(true)
      setLoading(false)
    } catch (error: any) {
      showToastError(error.message ?? 'Generic errore')
      setLoading(false)
    }
  }

  const refreshData = async () => {
    try {
      if (!missionCode || !warehouse) throw new Error('Codice missione o warehouse non trovato')
      const res = (await InboundWorkstationApi.getSapInbounds(missionCode, warehouse)).content
      setNoteModalRows(res)
    } catch (error: any) {
      showToastError(error.message ?? 'Generic errore')
    }
  }

  const confirm = async () => {
    try {
      if (!missionCode || !warehouse || !merchCategory)
        throw new Error('Codice missione, warehouse o categoria merceologica non trovata')
      setShowPageOverlayLoader?.(true)
      const res = await InboundWorkstationApi.checkInboundWorkstationDocumentTypeSetting(missionCode, warehouse)
      if (!res) {
        setShowPageOverlayLoader?.(false)
        setIsNoteModalVisible(true)
      }

      if (data?.totalCqToDo !== 0) {
        await confirmInboundWorkstation()

        evtSource.current = new EventSource(
          config.endpoint +
            `api/v1/sse/getData/sapInboundPostazioneConfirm/total_${AppStore.loggedUser?.username ?? '---'}_${
              data?.id ?? new Date().getTime()
            }?access_token=${AppStore.authToken}`,
          { withCredentials: false }
        )

        evtSource.current.onmessage = async (event) => {
          if (!evtSource.current) return
          const eventData: any = JSON.parse(event.data)
          if (eventData?.data?.success) {
            evtSource.current.close()
            setShowPageOverlayLoader?.(false)
            showToast({ status: 'success', description: 'Missione chiusa con successo' })
            onConfirmationDone?.()
          } else {
            evtSource.current.close()
            setShowPageOverlayLoader?.(false)
            showToastError('Errore durante la chiusura della missione')
          }
        }

        evtSource.current.onerror = async (event) => {
          console.log('error', event)
          if (!evtSource.current) return
          evtSource.current.close()
          setShowPageOverlayLoader?.(false)
          setLoading(false)
          showToastError('Errore durante la chiusura della missione')
        }
      } else {
        const collaudati = Number(data?.totalQty) - Number(data?.totalCqToDo)
        let message = 'Non ci sono più prodotti da collaudare. Sei sicuro di voler chiudere la missione?'
        if (collaudati < Number(data?.totalReturn)) {
          message =
            'Non ci sono più prodotti da collaudare ed il numero di collaudati è inferiore al numero dei rientrati. Sei sicuro di voler chiudere la missione?'
        }
        const confirmed = await askUserConfirmation(message, 'Conferma chiusura missione', 'No', 'Si')
        if (confirmed) {
          await confirmInboundWorkstation()
          evtSource.current = new EventSource(
            config.endpoint +
              `api/v1/sse/getData/sapInboundPostazioneConfirm/total_${AppStore.loggedUser?.username ?? '---'}_${
                data.id ?? new Date().getTime()
              }?access_token=${AppStore.authToken}`,
            { withCredentials: false }
          )

          evtSource.current.onmessage = async (event) => {
            if (!evtSource.current) return
            const eventData: any = JSON.parse(event.data)
            if (eventData?.data?.success) {
              evtSource.current.close()
              setShowPageOverlayLoader?.(false)
              showToast({ status: 'success', description: 'Missione chiusa con successo' })
              onConfirmationDone?.()
            } else {
              evtSource.current.close()
              setShowPageOverlayLoader?.(false)
              showToastError('Errore durante la chiusura della missione')
            }
          }

          evtSource.current.onerror = async (event) => {
            console.log('error', event)
            if (!evtSource.current) return
            evtSource.current.close()
            setShowPageOverlayLoader?.(false)
            setLoading(false)
            showToastError('Errore durante la chiusura della missione')
          }
        }
      }
    } catch (error: any) {
      if (evtSource.current) evtSource.current.close()
      showToastError(error.message ?? 'Generic errore')
      setShowPageOverlayLoader?.(false)
    }
  }

  const confirmInboundWorkstation = async () => {
    await InboundWorkstationApi.confirmInboundWorkstation({
      mission: missionCode ?? '',
      totalCqToDo: Number(data?.totalCqToDo) ?? 0,
      totalReturn: Number(data?.totalReturn) ?? 0,
      warehouse: warehouse ?? '',
    })
  }

  return (
    <Box style={style}>
      <InfoContainer>
        <FormSchemaTextField
          defaultValue={undefined}
          field={{ type: 'text', label: __(T.misc.total_mission_qty), name: 'total_mission_qty' }}
          index={0}
          value={data?.totalQty?.toString() ?? ''}
          disabled
          onChange={() => {}}
        />
        <Spacer height="10" />
        <div
          style={{
            backgroundColor: (data?.totalQtyRead ?? 0) < (data?.totalQty ?? 0) ? '#fd9393' : 'transparent',
            borderRadius: 10,
          }}
        >
          <FormSchemaTextField
            defaultValue={undefined}
            field={{ type: 'text', label: __(T.misc.inbound_read_qty), name: 'inbound_read_qty' }}
            index={0}
            value={data?.totalQtyRead?.toString() ?? ''}
            disabled
            onChange={() => {}}
          />
        </div>
        <Spacer height="10" />
        <FormSchemaTextField
          defaultValue={undefined}
          field={{ type: 'text', label: __(T.misc.checked_qty), name: 'checked_qty' }}
          index={0}
          value={data?.totalCqQty?.toString() ?? ''}
          disabled
          onChange={() => {}}
        />
        <Spacer height="10" />
        <FormSchemaTextField
          defaultValue={undefined}
          field={{ type: 'text', label: __(T.misc.checked), name: 'checked' }}
          index={0}
          value={
            data?.totalQty && data?.totalCqToDo ? (Number(data?.totalQty) - Number(data?.totalCqToDo))?.toString() : ''
          }
          disabled
          onChange={() => {}}
        />
        <Spacer height="10" />
        <Box row flex>
          <Box flex>
            <FormSchemaTextField
              defaultValue={undefined}
              field={{ type: 'text', label: __(T.misc.returned), name: 'returned' }}
              index={0}
              value={data?.totalReturn?.toString() ?? ''}
              disabled
              onChange={() => {}}
            />
          </Box>
          <Spacer width={10} />
          <Box flex>
            <FormSchemaTextField
              defaultValue={undefined}
              field={{ type: 'text', label: __(T.misc.damaged), name: 'damaged' }}
              index={0}
              value={data?.totalDefected?.toString() ?? ''}
              disabled
              onChange={() => {}}
            />
          </Box>
        </Box>
        <Spacer height="10" />
        <FormSchemaTextField
          defaultValue={undefined}
          field={{ type: 'text', label: __(T.misc.qty_damaged_first_check), name: 'qty_damaged_first_check' }}
          index={0}
          value={data?.firstCqQty?.toString() ?? ''}
          disabled
          onChange={() => {}}
        />
        <Spacer height="10" />
        <FormSchemaTextField
          defaultValue={undefined}
          field={{ type: 'text', label: __(T.misc.first_check_date), name: 'first_check_date' }}
          index={0}
          value={data?.firstCqDate ? format(new Date(data?.firstCqDate), 'dd/MM/yyyy HH:mm') : ''}
          disabled
          onChange={() => {}}
        />
        <Spacer height="10" />
        <FormSchemaTextField
          defaultValue={undefined}
          field={{ type: 'text', label: __(T.misc.qty_damaged_second_check), name: 'qty_damaged_second_check' }}
          index={0}
          value={data?.secondCqQty?.toString() ?? ''}
          disabled
          onChange={() => {}}
        />
        <Spacer height="10" />
        <FormSchemaTextField
          defaultValue={undefined}
          field={{ type: 'text', label: __(T.misc.second_check_date), name: 'second_check_date' }}
          index={0}
          value={data?.secondCqDate ? format(new Date(data?.secondCqDate), 'dd/MM/yyyy HH:mm') : ''}
          disabled
          onChange={() => {}}
        />
        <Spacer height="10" />
        <Button
          variant="secondary"
          title={__(T.misc.note)}
          loading={loading}
          disabled={!missionCode || !warehouse || !merchCategory}
          onClick={() => {
            openNotesModal()
          }}
        />
        <Spacer height="10" />
        <Button
          variant="primary"
          disabled={!missionCode || !warehouse || !merchCategory}
          title={__(T.misc.confirm)}
          onClick={confirm}
        />
      </InfoContainer>
      <InboundWorkstationNoteModal
        rows={noteModalRows}
        visible={isNoteModalVisible}
        onRowPartialConfirmationDone={onRowPartialConfirmationDone}
        onClose={() => setIsNoteModalVisible(false)}
        refreshData={refreshData}
        refreshWorkstationData={refreshWorkstationData}
        setShowPageOverlayLoader={setShowPageOverlayLoader}
      />
    </Box>
  )
}
