import {
  DecodedItem,
  GroupedItemsByProduct,
  ShippingParcel,
  ShippingParcelChecklist,
  TmrProduct as StwProduct,
  TmrTag,
} from 'stylewhere/api'
import { FormSchemaData, FormSchemaRouteProps, OperationConfig, RfidReader } from 'stylewhere/shared'
import { showToastError, filterIgnoredItems, enabledWamGroupedProduct } from 'stylewhere/utils'

/** Stato base condiviso da tutte le route di Operation Reading */
export type OperationReadingState = {
  items: DecodedItem[]
  loading: boolean
  formData: FormSchemaData
  tags?: TmrTag[]
  detected?: number
}

/** Prop condivise da tutte le route di Operation Reading */
export type OperationReadingProps<State = OperationReadingState> = FormSchemaRouteProps<
  { opCode: string; id?: string },
  State
>

export class OperationReadingProvider {
  static currentTag
  static processedItems
  static tagsRead

  static async init(
    operation: OperationConfig,
    locationState: Partial<OperationReadingState>,
    backCallback: () => void,
    customFunction?: () => void,
    disabledFormData?: boolean
  ) {
    if (!locationState.formData && !disabledFormData) {
      backCallback()
      return
    }

    try {
      await RfidReader.initialize()
      customFunction?.()
      // Aggiungi tags eventualmente letti durante la start
      locationState.tags?.forEach((tag) => RfidReader.emulateTag(tag))
      operation.autostartAntenna && RfidReader.start()
    } catch (err) {
      showToastError(err)
      backCallback()
    }
  }

  static checkItemStatus(operation: OperationConfig, item: DecodedItem<string>) {
    if (!item.item || item.statuses?.some((status) => operation.itemStatuses.ignore.includes(status))) {
      if (!item.item) {
        item.item = {} as any //Forzo item non a sistema item
      }
      item.item!.status = 'ignored'
      return item
    }

    if (item.statuses?.some((status) => operation.itemStatuses.ignoreWithReason.includes(status)) && item?.item)
      item.item.status = 'ignoredWithReason'
    else if (item.statuses?.some((status) => operation.itemStatuses.error.includes(status)) && item?.item)
      item.item.status = 'error'
    else if (item.statuses?.some((status) => operation.itemStatuses.warning.includes(status)) && item?.item)
      item.item.status = 'warning'
    return item
  }

  static async processSingleItems(
    decodedItem: DecodedItem,
    operation: OperationConfig,
    formData: FormSchemaData,
    extensions?: any
  ) {
    let processedItem = OperationReadingProvider.checkItemStatus(operation, decodedItem)
    if (!processedItem.item || processedItem.item.status === 'ignored') {
      return processedItem
    } else if (processedItem.item.status === 'ignoredWithReason') {
      return processedItem
    } else if (
      this.processedItems.find((itm) => itm.item !== null && itm.item?.id === processedItem.item?.id) === undefined
    ) {
      if (extensions) processedItem = await extensions.processItem(operation, formData ?? {}, processedItem)
      if (processedItem && processedItem.item !== null) {
        return processedItem
      }
    }
    return undefined
  }

  static async processItems(
    operation: OperationConfig,
    itemMapFromReader: { [tagCode: string]: DecodedItem },
    formData: FormSchemaData,
    extensions?: any
  ) {
    const keys = Object.keys(itemMapFromReader)
    if (this.currentTag < keys.length - 1) {
      this.currentTag++
      const decodedItem = itemMapFromReader[this.tagsRead[this.currentTag]]
      const processedItem = await this.processSingleItems(decodedItem, operation, formData, extensions)
      if (processedItem) {
        processedItem.identifierCode = this.tagsRead[this.currentTag]
        this.processedItems.push(processedItem)
      }
      return this.processItems(operation, itemMapFromReader, formData, extensions)
    }
    return this.processedItems
  }

  static async processDecodedItems<T extends DecodedItem[] = DecodedItem[]>(
    operation: OperationConfig,
    itemMapFromReader: { [tagCode: string]: DecodedItem },
    items: T,
    formData: FormSchemaData,
    extensions?: any
  ) {
    this.currentTag = -1
    this.tagsRead = []
    this.processedItems = []
    for (let t = 0; t < items.length; t++) {
      this.processedItems.push(items[t])
    }
    const keys = Object.keys(itemMapFromReader)
    keys.map((tagCode) => {
      this.tagsRead.push(tagCode)
    })
    await this.processItems(operation, itemMapFromReader, formData, extensions)

    if (this.processedItems.length > 1 && operation.enabledCheckItemZone) {
      const itemZoneBase = this.processedItems[0].zone ? this.processedItems[0].zone.id : ''
      if (itemZoneBase !== '') {
        let itemZone, itemProcessed
        for (let i = 1; i < this.processedItems.length; i++) {
          itemProcessed = this.processedItems[i]
          itemZone = itemProcessed && itemProcessed.zone ? itemProcessed.zone.id : ''
          if (itemZone !== '' && itemZone !== itemZoneBase && itemProcessed.item) {
            itemProcessed.item.status = 'warning'
          }
        }
      }
    }
    return this.processedItems
  }

  static removeItem<T extends DecodedItem>(decodedItem: T, items: T[]) {
    const { item, identifierCode } = decodedItem
    if (!item && !identifierCode) return items

    const idx = items.findIndex((i) => item?.id === i.item?.id || identifierCode === i?.identifierCode)
    if (idx > -1) {
      items.splice(idx, 1)
      if (item) RfidReader.removeTags(item.identifiers.map(({ code }) => code))
      else if (identifierCode) RfidReader.removeTags([identifierCode])
    }
    return items
  }

  static groupItems(
    items: DecodedItem[],
    groupByProduct: boolean,
    operation?: OperationConfig,
    extension?: any,
    checklist?: ShippingParcelChecklist | null,
    products?: { [upc: string]: StwProduct },
    useDisplayMode?: boolean
  ) {
    let isUnexpected
    let row
    const useDisplay = useDisplayMode && operation?.readingsDisplayMode === 'groupedByProduct'
    const rowsByProduct: GroupedItemsByProduct[] = []
    if (operation?.readingsDisplayMode === 'groupedByProduct' || operation?.readingsDisplayMode === 'item') {
      const updateExpecteds = (productCode: string, quantity?: number, itemId?: string, detected?: number) => {
        if (!products) return
        const product = products?.[productCode]
        if (!product) {
          throw new Error(`Checklist product ${productCode} missing from parcel`)
        }

        const relatedItems =
          !groupByProduct && !useDisplay ? [] : items.filter((itm) => itm.item?.product?.code === productCode)

        if (!groupByProduct && !useDisplay) {
          row = {
            item: { id: itemId, product: product },
            detected: detected ?? 0,
            expected: 0,
            unexpected: 0,
            items: relatedItems,
            status: undefined,
          }
          rowsByProduct.push(row)
        } else {
          row = rowsByProduct.find((p) => p.product?.id === product.id)
          if (!row) {
            row = {
              product,
              detected: detected ?? 0,
              expected: 0,
              unexpected: 0,
              items: relatedItems,
              status: undefined,
            }
            rowsByProduct.push(row)
          }
        }
        row.expected += quantity ?? 1
      }
      if (checklist && checklist !== null) {
        if (!groupByProduct) {
          ;(checklist.checklistItems ?? []).forEach(({ productCode, itemId, detected }) =>
            updateExpecteds(productCode, 1, itemId, detected ?? 0)
          )
        } else {
          ;(checklist.checklistProducts ?? []).forEach(({ productCode, quantity, detected }) =>
            updateExpecteds(productCode, quantity, undefined, detected ?? 0)
          )
        }
      }

      items.forEach((decodedItem) => {
        if (
          !decodedItem.item ||
          !decodedItem.item.product ||
          decodedItem.item.status === 'ignored' ||
          decodedItem.item.status === 'ignoredWithReason'
        )
          return
        isUnexpected = false
        if (!groupByProduct) {
          if (useDisplay) {
            const { product } = decodedItem.item
            if (enabledWamGroupedProduct()) {
              if (decodedItem.item && decodedItem.item.attributes && decodedItem.item.attributes.wam) {
                if (!product.attributes) {
                  product.attributes = {}
                }
                product.attributes.wam = decodedItem.item.attributes.wam
                row = rowsByProduct.find((p) => p.item?.product.attributes?.wam === product.attributes?.wam)
              } else {
                row = rowsByProduct.find((p) => p.item?.product.id === product.id)
              }
            } else {
              row = rowsByProduct.find((p) => p.item?.product?.id === product.id)
            }
          } else {
            row = rowsByProduct.find((p) => p.item?.id === decodedItem.item?.id)
          }
          if (!row) {
            row = {
              item: decodedItem.item,
              statuses: decodedItem.statuses,
              detected: 1,
              expected: 0,
              unexpected: 0,
              items: [],
              status: undefined,
              added: true,
            }
            isUnexpected = true
          } else {
            row.detected++
          }
        } else {
          const { product } = decodedItem.item
          if (enabledWamGroupedProduct()) {
            if (decodedItem.item && decodedItem.item.attributes && decodedItem.item.attributes.wam) {
              if (!product.attributes) {
                product.attributes = {}
              }
              product.attributes.wam = decodedItem.item.attributes.wam
              row = rowsByProduct.find((p) => p.product.attributes?.wam === product.attributes?.wam)
            } else {
              row = rowsByProduct.find((p) => p.product.id === product.id)
            }
          } else {
            row = rowsByProduct.find((p) => p.product.id === product.id)
          }
          if (!row) {
            row = {
              product,
              detected: 1,
              expected: 0,
              unexpected: 0,
              items: [decodedItem],
              status: undefined,
              added: true,
            }
            isUnexpected = true
          } else {
            row.detected++
            row.items.push(decodedItem)
          }
        }
        row.unexpected = row.expected > 0 && row.detected > row.expected ? row.detected - row.expected : 0

        // Stato
        if (decodedItem.item.status === 'error') {
          row.status = 'error'
        } else if (decodedItem.item.status === 'warning' && row.status !== 'error') {
          row.status = 'warning'
        }

        if (isUnexpected) {
          rowsByProduct.push(row)
        }
      })
      return (
        rowsByProduct
          // Processa le righe con l'extension (se ritorna null la riga deve essere saltata)
          .map((rowData) => extension.processRow(operation, rowData))
          .filter((rowData): rowData is GroupedItemsByProduct => rowData !== null && row)
      )
    }
    throw new Error(`Unsupported operation readingsDisplayMode ${operation?.readingsDisplayMode}`)
  }
}
