import { set } from 'lodash'
import { useEffect, useState } from 'react'
import { CqImageApi } from 'stylewhere/api/CqImage'
import { showToastError } from 'stylewhere/shared/utils'
import { Image as ChakraImage, Skeleton, SkeletonText } from '@chakra-ui/react'

interface Props {
  images: string[]
}

export default function ImagesGallery({ images }: Props) {
  const [loading, setLoading] = useState(true)
  const [imagesUrls, setImagesUrls] = useState<string[]>([])

  useEffect(() => {
    async function fetchImages() {
      try {
        const urls = await CqImageApi.getImages(images)
        setImagesUrls(urls)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        showToastError('Error fetching images')
      }
    }
    fetchImages()
  }, [images])

  const renderImageLoading = () => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {images?.map((image, index) => (
          <Skeleton style={{ margin: 5, width: 200, height: 200, maxWidth: 200 }}></Skeleton>
        ))}
      </div>
    )
  }

  if (loading) {
    return renderImageLoading()
  } else
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {imagesUrls?.map((image) => (
          <ChakraImage
            style={{ margin: 5, width: 200, maxWidth: 200 }}
            src={image}
            //fallbackSrc="https://via.placeholder.com/150"
          />
        ))}
      </div>
    )
}
