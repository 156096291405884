import { Component } from 'react'
import {
  AntennaButton,
  Box,
  CollapsibleSection,
  FormSchemaTextField,
  FullLoadingLayer,
  Icons,
  InboundWorkstationDocumentModal,
  InboundWorkstationQualityControlModal,
  InfoContainer,
  KeyValueRow,
  Page,
  QualityControlPanel,
  Table,
} from 'stylewhere/components'
import { Router, OperationReadingProps, RfidReader, AppStore } from 'stylewhere/shared'
import styled from '@emotion/styled'
import { T, __ } from 'stylewhere/shared/i18n'
import { InboundWorkstationRightForm } from 'stylewhere/components/InboundWorkstationRightForm'
import { InboundWorkstationApi } from 'stylewhere/api'
import { merchCategories } from 'stylewhere/config'
import {
  Defect,
  DefectsTable,
  Document,
  InboundWorkstationItemConfirmation,
  InboundWorkstationItemInfo,
  InboundWorkstationReportData,
  MeasurementsFields,
  SessionItem,
  TmrItem,
} from 'stylewhere/api/types'
import { showToast, showToastError } from 'stylewhere/shared/utils'
import { format } from 'date-fns'
import { CustomItemApi } from 'stylewhere/api/CustomItem'

interface State {
  mission?: any
  readItems?: InboundWorkstationItemInfo[]
  selectedCatMerch?: { value: string; label: string }
  loading?: boolean
  parentDefects?: Defect[]
  childDefects?: Defect[]
  selectedParentDefect?: Defect
  selectedChildDefect?: Defect
  addedDefects?: DefectsTable[]
  physicalStorage?: string
  inboundWorkstationData?: InboundWorkstationReportData
  documents?: Document[]
  sessionItems?: SessionItem[]
  measurementFields?: MeasurementsFields[]
  showDocumentModal?: boolean
  selectedDocument?: Document
  showQualityControlModal?: boolean
  selectedQualityControl?: SessionItem
  currentReadItem?: InboundWorkstationItemInfo
  showPageOverlayLoader?: boolean
  currentEpc?: string
}

export default class InboundWorkstation extends Component<OperationReadingProps<State>, State> {
  locationState = Router.getLocationState<State>(this.props)

  state: State = {
    loading: true,
    physicalStorage: 'V00',
    showPageOverlayLoader: false,
  }

  // Struttura per la tabella "Prodotto"
  productTableStructure = [
    {
      key: 'epc',
      label: 'EPC',
      customRender: (item: InboundWorkstationItemInfo, column) => item.firstSapInboundItem?.epc ?? '',
      width: 260,
    },
    {
      key: 'wam',
      label: 'WAM',
      customRender: (item: InboundWorkstationItemInfo, column) => item.firstSapInboundItem?.wam ?? '',
      flex: 1,
    },
    {
      key: 'material',
      label: 'Materiale',
      customRender: (item: InboundWorkstationItemInfo, column) => item.firstSapInboundItem?.matNr ?? '',
      flex: 1,
    },
    {
      key: 'sizeColor',
      label: 'Taglia/Colore',
      customRender: (item: InboundWorkstationItemInfo, column) => item.firstSapInboundItem?.j3ASIZE ?? '',
      flex: 1,
    },
    {
      key: 'orderCode',
      label: 'Commessa',
      customRender: (item: InboundWorkstationItemInfo, column) => item.firstSapInboundItem?.commessa ?? '',
      flex: 1,
    },
  ]

  // Struttura per la tabella "Verbali di sblocco"
  releaseRecordsTableStructure = [
    {
      key: 'creationDate',
      label: 'Data Creazione',
      customRender: (item: Document, column) => format(new Date(item.creationDate), 'dd/MM/yyyy HH:mm'),
      flex: 1,
    },
    {
      key: 'supplier',
      label: 'Fornitore',
      customRender: (item: Document, column) => {
        const workerExternalCode = item?.attributes?.find((a) => a.key === 'workerExternalCode')?.value
        return workerExternalCode ?? ''
      },
      flex: 1,
    },
    {
      key: 'user',
      label: 'Utente',
      customRender: (item: Document, column) => {
        const value = item?.qualityControlUser ?? item?.createdBy
        const name = value?.name
        const surname = value?.surname
        if (name && surname) return `${name} ${surname}`
        else return value.username ?? '---'
      },
      flex: 1,
    },
    {
      key: 'material',
      label: 'Materiale',
      customRender: (item: Document, column) => {
        const material = item?.attributes?.find((a) => a.key === 'material')?.value
        return material ?? ''
      },
      flex: 1,
    },
    {
      key: 'status',
      label: 'Stato',
      value: 'status',
      flex: 1,
    },
    {
      key: 'details',
      label: 'Dettagli',
      customRender: (item: Document, column) => (
        <Box center>
          <Icons.Info
            onClick={() => {
              this.setState({ showDocumentModal: true, selectedDocument: item })
            }}
          />
        </Box>
      ),
      flex: 1,
    },
  ]

  // Struttura per la tabella "Controlli qualità"
  qualityChecksTableStructure = [
    {
      key: 'creationDate',
      label: 'Data Creazione',
      value: 'creationDate',
      customRender: (item: SessionItem, column) => format(new Date(item.creationDate), 'dd/MM/yyyy HH:mm'),
      flex: 1,
    },
    {
      key: 'user',
      label: 'Utente',
      customRender: (item: SessionItem, column) => {
        const value = item?.session?.qualityControlUser ?? item?.createdBy
        const name = value?.name
        const surname = value?.surname
        if (name && surname) return `${name} ${surname}`
        else return value?.username ?? '---'
      },
      flex: 1,
    },
    {
      key: 'material',
      label: 'Materiale',
      customRender: (item: SessionItem, column) => item.attributes.find((a) => a.key === 'material')?.value ?? '',
      flex: 1,
    },
    {
      key: 'sizeColor',
      label: 'Taglia/Colore',
      customRender: (item: SessionItem, column) => item.attributes.find((a) => a.key === 'sizeColor')?.value ?? '',
      flex: 1,
    },
    {
      key: 'cause',
      label: 'Causale',
      customRender: (item: SessionItem, column) => {
        const cause = item.defects?.find((d) => d.parent)
        return cause?.description ?? ''
      },
      flex: 1,
    },
    {
      key: 'notes',
      label: 'Note',
      value: 'notes',
      flex: 1,
    },
    {
      key: 'status',
      label: 'Stato',
      customRender: (item: SessionItem, column) => {
        const traslation = __(T.misc[item.status]) ?? item.status
        return <Box>{traslation}</Box>
      },
      flex: 1,
    },
    {
      key: 'details',
      label: 'Dettagli',
      customRender: (item: SessionItem, column) => (
        <Box center>
          <Icons.Info
            onClick={() => {
              this.setState({ showQualityControlModal: true, selectedQualityControl: item })
            }}
          />
        </Box>
      ),
      flex: 1,
    },
  ]

  async componentDidMount() {
    RfidReader.setOnTagReadCallback((tag) => this.onTagReceived(tag.epc))
    await this.fetchAppSettings()
  }

  fetchAppSettings = async () => {
    try {
      const res = await InboundWorkstationApi.getApplicationSettings('measurementFields')
      if (!res.content || res.content.length === 0) throw new Error('Errore, nessun campo di misura trovato')
      const measurementFields = res.content[0].value
      this.setState({ loading: false, measurementFields })
    } catch (error: any) {
      showToastError(error.message)
      this.setState({ loading: false })
    }
  }

  onTagReceived = async (epc: string) => {
    await RfidReader.stop()
    try {
      const { mission, physicalStorage, documents, sessionItems } = this.state
      const newDocuments: Document[] = []
      const newSessionItems: SessionItem[] = []
      if (!mission?.code || !physicalStorage || !epc)
        throw new Error('Errore, dati mancanti tra missione o storage fisico o epc')

      /*** item creation if the epc is not in the system but the product is retived by GS1 */
      if (!AppStore?.defaultWorkstation?.placeId)
        throw new Error('Postazione non selezionata in dashboard, selezionare una postazione per continuare')
      const items = (await CustomItemApi.batchDecode(
        [epc],
        AppStore?.defaultWorkstation?.placeId ?? AppStore.loggedUser?.place?.id ?? '',
        AppStore.loggedUser?.id ?? ''
      )) as { [tagCode: string]: TmrItem | null }
      if (!items) return

      if (Object.values(items).length === 0) throw new Error('Articolo letto non trovato')

      if (!items[epc] || !items[epc]?.attributes || !items[epc]?.attributes?.wam)
        throw new Error('Articolo letto non trovato')

      /************** */

      const res = await InboundWorkstationApi.getItemInfo(this.state.mission?.code, epc, physicalStorage)
      if (!res) throw new Error('Errore, nessun articolo trovato')
      if (
        !res?.firstSapInboundItem?.matNr &&
        !res?.document?.attributes?.find((a) => a.key === 'material')?.value &&
        !res?.sessionItem?.attributes?.find((a) => a.key === 'material')?.value
      )
        throw new Error('Il pezzo non ha un materiale associato')
      if (res && res.document) newDocuments.push(res.document)
      if (res && res.sessionItem) newSessionItems.push(res.sessionItem)
      this.setState({
        readItems: [res],
        documents: newDocuments,
        sessionItems: newSessionItems,
        currentReadItem: res,
        currentEpc: epc,
      })
    } catch (error: any) {
      showToastError(error.message)
    }
  }

  fetchInboundWorkstationData = async () => {
    const { mission, selectedCatMerch, physicalStorage } = this.state
    try {
      if (!mission?.code) throw new Error('Compilare missione')
      if (!selectedCatMerch) throw new Error('Selezionare una categoria merceologica')
      this.setState({ loading: true })
      this.setShowPageOverlayLoader(true)
      const res = await InboundWorkstationApi.getInboundWorkstationData(mission?.code, physicalStorage ?? 'V00')
      this.setState({ loading: false, inboundWorkstationData: res }, async () => {
        await this.updateInboundWorkstationData()
      })
      this.setShowPageOverlayLoader(false)
    } catch (error: any) {
      this.setShowPageOverlayLoader(false)
      showToastError(error.message)
    }
  }

  fetchDefectsByCatMerch = async (category: string = merchCategories[0]) => {
    try {
      const res = await InboundWorkstationApi.getDefectsParentsByMerchCategory(category?.toUpperCase())
      const parentDefects: Defect[] = res.content ?? []
      this.setState({ parentDefects })
    } catch (error) {
      console.error(error)
    }
  }

  clear = () => {
    RfidReader.clear()
    this.setState({
      currentReadItem: undefined,
      readItems: undefined,
      documents: undefined,
      sessionItems: undefined,
      currentEpc: undefined,
    })
  }

  goBack = () => {
    Router.navigate('/')
  }

  onMerchCateogrySelected = async (category: { value: string; label: string }) => {
    const { mission, inboundWorkstationData } = this.state
    this.setState({ selectedCatMerch: category }, () => {
      if (mission && !inboundWorkstationData) this.fetchInboundWorkstationData()
      //update updateSapInboundDocumentType if the inboundWorkstationData are present
      if (inboundWorkstationData) this.updateInboundWorkstationData()
    })
    await this.fetchDefectsByCatMerch(category.value)
  }

  updateInboundWorkstationData = async () => {
    try {
      const { inboundWorkstationData, selectedCatMerch } = this.state
      if (!selectedCatMerch || !selectedCatMerch.value || !inboundWorkstationData)
        throw new Error('Errore, dati mancanti per updateSapInboundDocumentType')
      this.setState({ loading: true })
      const res = await InboundWorkstationApi.updateSapInboundDocumentType(
        inboundWorkstationData.id,
        selectedCatMerch.value
      )
      this.setState({ loading: false })
    } catch (error: any) {
      showToastError(error.message)
      this.setState({ loading: false })
    }
  }

  onParentDefectSelected = async (defect: Defect) => {
    this.setState({ selectedParentDefect: defect })
    const res = await InboundWorkstationApi.getDefectsChildsByMerchCategory(
      this.state.selectedCatMerch?.value?.toUpperCase() ?? '',
      defect.id
    )
    const childDefects: Defect[] = res.content ?? []
    this.setState({ childDefects })
  }

  onChildDefectSelected = (defect: Defect) => {
    this.setState({ selectedChildDefect: defect })
  }

  clearSelectedDefects = () => {
    this.setState({ selectedParentDefect: undefined, selectedChildDefect: undefined })
  }

  updateAddedDefects = (defects: DefectsTable[]) => {
    this.setState({ addedDefects: defects })
  }

  onMissionCodeChange = (text: string) => {
    const {
      mission,
      inboundWorkstationData,
      sessionItems,
      documents,
      readItems,
      selectedCatMerch,
      currentEpc,
      currentReadItem,
    } = this.state
    const clearData = text.length === 0
    this.setState({
      mission: { ...mission, id: '3', code: text },
      inboundWorkstationData: clearData ? undefined : inboundWorkstationData,
      sessionItems: clearData ? undefined : sessionItems,
      documents: clearData ? undefined : documents,
      readItems: clearData ? undefined : readItems,
      selectedCatMerch: clearData ? undefined : selectedCatMerch,
      currentEpc: clearData ? undefined : currentEpc,
      currentReadItem: clearData ? undefined : currentReadItem,
    })
  }

  onItemCqConfirm = async (item: InboundWorkstationItemConfirmation, onSuccessCallback: () => void) => {
    try {
      const { currentEpc } = this.state
      await InboundWorkstationApi.createSapQualityControlItem(item)
      await this.fetchInboundWorkstationData()
      this.setState({
        currentReadItem: undefined,
        selectedChildDefect: undefined,
        selectedParentDefect: undefined,
        readItems: [],
        addedDefects: [],
        selectedDocument: undefined,
        selectedQualityControl: undefined,
        documents: [],
        sessionItems: [],
      })
      showToast({ status: 'success', description: 'Controllo qualità completato con successo' })

      currentEpc && RfidReader.removeTags([currentEpc])
      onSuccessCallback()
    } catch (error: any) {
      showToastError(error.message)
    }
  }

  setShowPageOverlayLoader = (show: boolean) => {
    this.setState({ showPageOverlayLoader: show })
  }

  clearAll = () => {
    this.setState({
      mission: undefined,
      readItems: undefined,
      selectedCatMerch: undefined,
      parentDefects: undefined,
      childDefects: undefined,
      inboundWorkstationData: undefined,
      documents: undefined,
      sessionItems: undefined,
      measurementFields: undefined,
      showDocumentModal: undefined,
      showQualityControlModal: undefined,
      currentReadItem: undefined,
      showPageOverlayLoader: false,
      loading: false,
      selectedParentDefect: undefined,
      selectedChildDefect: undefined,
      addedDefects: undefined,
      selectedDocument: undefined,
      selectedQualityControl: undefined,
      currentEpc: undefined,
    })
  }

  render() {
    const {
      mission,
      readItems,
      selectedCatMerch,
      parentDefects,
      childDefects,
      physicalStorage,
      inboundWorkstationData,
      documents,
      sessionItems,
      measurementFields,
      showDocumentModal,
      showQualityControlModal,
      currentReadItem,
      showPageOverlayLoader,
      selectedDocument,
      selectedQualityControl,
    } = this.state
    const hideClear = (readItems?.length ?? 0) === 0
    const isAntennaButtonDisabled = !inboundWorkstationData
    return (
      <Page title={'Postazione singola CQ'} enableEmulation>
        <Page.Content notBoxed>
          <InfoContainer>
            <FormContainer>
              <FormRow>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.mission), name: 'Mission', focus: true }}
                    index={0}
                    value={mission?.code ?? ''}
                    onChange={this.onMissionCodeChange}
                    onEnter={() => this.fetchInboundWorkstationData()}
                  />
                </FormElement>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={physicalStorage}
                    field={{ type: 'text', label: __(T.fields.physical_storage), name: 'PhysicalStorage' }}
                    index={2}
                    disabled={true}
                    value={physicalStorage}
                    onChange={() => {}}
                  />
                </FormElement>
                <FormElement>
                  <KeyValueRow
                    label={__(T.fields.product_category)}
                    style={{ width: '100%' }}
                    value={selectedCatMerch?.label}
                    optionModal={{
                      onSelect: (cat) => this.onMerchCateogrySelected(cat[0]),
                      field: 'label',
                      options: merchCategories.map((cat) => ({ label: cat, value: cat })),
                      selected: [selectedCatMerch],
                      title: __(T.fields.product_category),
                      searchable: true,
                    }}
                  />
                </FormElement>
              </FormRow>
              <FormRow>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.mission_type), name: 'MissionType' }}
                    index={1}
                    value={inboundWorkstationData?.tipoStock ?? ''}
                    disabled
                    onChange={(text: string) => this.setState({ mission: { ...mission, type: text } })}
                  />
                </FormElement>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.season), name: 'Season' }}
                    index={5}
                    disabled
                    value={inboundWorkstationData?.trilogySeason ?? ''}
                    onChange={(text: string) => this.setState({ mission: { ...mission, season: text } })}
                  />
                </FormElement>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.notification), name: 'Notification' }}
                    index={8}
                    disabled
                    value={inboundWorkstationData?.numNotifica ?? ''}
                    onChange={(text: string) => this.setState({ mission: { ...mission, notification: text } })}
                  />
                </FormElement>
              </FormRow>
              <FormRow>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.supplier), name: 'Supplier' }}
                    index={4}
                    value={inboundWorkstationData?.lifNr ?? ''}
                    disabled
                    onChange={(text: string) =>
                      this.setState({ mission: { ...mission, supplier: { ...mission.supplier, code: text } } })
                    }
                  />
                </FormElement>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.ddt), name: 'Ddt' }}
                    index={6}
                    disabled
                    value={inboundWorkstationData?.ddt ?? ''}
                    onChange={(text: string) => this.setState({ mission: { ...mission, ddt: text } })}
                  />
                </FormElement>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.ddt_date), name: 'DDtDate' }}
                    index={7}
                    disabled
                    value={inboundWorkstationData?.dataDdt ?? ''}
                    onChange={(text: string) => this.setState({ mission: { ...mission, ddt_date: text } })}
                  />
                </FormElement>
              </FormRow>
              <FormRow>
                <FormElement>
                  <FormSchemaTextField
                    defaultValue={undefined}
                    field={{ type: 'text', label: __(T.fields.notification_date), name: 'NotificationDate' }}
                    index={9}
                    disabled
                    value={inboundWorkstationData?.dataNotifica ?? ''}
                    onChange={(text: string) => this.setState({ mission: { ...mission, notification_date: text } })}
                  />
                </FormElement>
              </FormRow>
            </FormContainer>
            <Box center mt={10}>
              <AntennaButton
                disabled={isAntennaButtonDisabled}
                style={{ width: 400 }}
                hideClear={hideClear}
                showPendingTags={false}
                onClear={() => this.clear()}
              />
            </Box>
          </InfoContainer>
          <Box
            row
            style={{
              flexWrap: 'wrap',
            }}
          >
            <Box flex={3} mr={10}>
              <InfoContainer>
                <CollapsibleSection title={__(T.misc.product)}>
                  <Table
                    data={readItems?.map((el, index) => ({ ...el, id: index.toString() }))}
                    structure={this.productTableStructure}
                  />
                </CollapsibleSection>
              </InfoContainer>
              <InfoContainer>
                <CollapsibleSection title={__(T.misc.release_reports)}>
                  <Table
                    data={documents?.map((dc, index) => ({ ...dc, id: index.toString() }))}
                    structure={this.releaseRecordsTableStructure}
                  />
                </CollapsibleSection>
              </InfoContainer>
              <InfoContainer>
                <CollapsibleSection title={__(T.misc.quality_checks)}>
                  <Table data={sessionItems} structure={this.qualityChecksTableStructure} />
                </CollapsibleSection>
              </InfoContainer>
              <InfoContainer>
                <CollapsibleSection title={__(T.misc.quality_check)}>
                  <QualityControlPanel
                    type="single"
                    mission={mission?.code}
                    warehouse={physicalStorage}
                    onParentDefectSelected={this.onParentDefectSelected}
                    onChildDefectSelected={this.onChildDefectSelected}
                    clearSelectedDefects={this.clearSelectedDefects}
                    parentDefects={parentDefects}
                    childDefects={childDefects}
                    currentSelectedMerch={selectedCatMerch?.value}
                    updateAddedDefects={this.updateAddedDefects}
                    measurementFields={measurementFields}
                    item={currentReadItem}
                    onItemConfirmed={this.onItemCqConfirm}
                  />
                </CollapsibleSection>
              </InfoContainer>
            </Box>
            <Box flex={1}>
              <InboundWorkstationRightForm
                merchCategory={selectedCatMerch?.value ?? undefined}
                missionCode={mission?.code ?? undefined}
                warehouse={physicalStorage ?? undefined}
                style={{ minWidth: 200, width: '100%' }}
                data={inboundWorkstationData}
                setShowPageOverlayLoader={this.setShowPageOverlayLoader}
                onRowPartialConfirmationDone={this.fetchInboundWorkstationData}
                refreshWorkstationData={this.fetchInboundWorkstationData}
                onConfirmationDone={this.clearAll}
              />
            </Box>
          </Box>
          {showDocumentModal && (
            <InboundWorkstationDocumentModal
              document={selectedDocument}
              sessionItems={sessionItems}
              visible={showDocumentModal}
              measurementsFields={measurementFields}
              onClose={() =>
                this.setState({
                  showDocumentModal: false,
                })
              }
            />
          )}
          {showQualityControlModal && (
            <InboundWorkstationQualityControlModal
              item={selectedQualityControl}
              measurementsFields={measurementFields}
              visible={showQualityControlModal}
              onClose={() => this.setState({ showQualityControlModal: false })}
            />
          )}
          {showPageOverlayLoader && <FullLoadingLayer />}
        </Page.Content>
      </Page>
    )
  }
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; // Mantiene lo spazio tra le righe
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px; // Mantiene lo spazio tra gli input nella stessa riga
  flex-wrap: wrap; // Gli elementi vanno a capo se non c'è abbastanza spazio
  justify-content: flex-start; // Allinea gli elementi all'inizio della riga
`

const FormElement = styled.div`
  flex: 1;
  min-width: 320px; // Imposta una larghezza minima per ogni elemento per garantire leggibilità
  box-sizing: border-box; // Include padding e bordo nel calcolo della larghezza
`

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`
