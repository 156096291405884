import { Component } from 'react'
import { OperationStart } from 'stylewhere/components'
import { Router, getDataFromSchema, ShippingOperationConfig, RemoteOperation, RfidReader } from 'stylewhere/shared'
import { ShippingExtensions } from 'stylewhere/extensions'
import { showToastError, isModalError } from 'stylewhere/utils'
import { Shippings, TmrTag } from 'stylewhere/api'
import type { Routes } from 'stylewhere/pages'
import { RouteComponentProps } from 'react-router'
import { T, __ } from 'stylewhere/i18n'

interface Params {
  opCode: string
}

export default class ShippingStart extends Component<RouteComponentProps<Params>> {
  submitPath: Routes = '/shipping/:opCode/reading'
  operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(Router.getMatchParams(this.props).opCode)
  checkingIdentifier = false
  isModal = false

  componentDidMount() {
    this.isModal = isModalError(this.operation)
    if (this.operation.startWithRfidSearch) {
      RfidReader.initialize()
      RfidReader.onTagReadCallback = this.onTagReadCallback
    }
  }

  onTagReadCallback = async (tag: TmrTag) => {
    if (this.checkingIdentifier) return
    this.checkingIdentifier = true
    RfidReader.stop()
    RfidReader.clear()
    try {
      const parcelByIdentifier = await Shippings.parcelByIdentifier(this.operation, tag.epc)
      const parcel = await Shippings.startParcel(this.operation, {
        parcelCode: parcelByIdentifier?.code || '',
        attributes: parcelByIdentifier?.attributes || {},
        operationId: this.operation.id,
        asn: parcelByIdentifier?.asn || {},
      })
      Router.navigate(
        this.submitPath,
        { opCode: this.operation.code },
        { state: { formData: {}, parcel, tags: [tag] } }
      )
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
    this.checkingIdentifier = false
  }

  onSubmit = async (formData, operation: ShippingOperationConfig, schema) => {
    try {
      if (operation.hasChecklist) {
        const payloadStartParcel: any = getDataFromSchema(formData, schema)
        const parcel = await Shippings.startParcel(operation, payloadStartParcel)
        Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData, parcel } })
      }
      Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData } })
    } catch (err) {
      showToastError(err, 'Start Parcel Error', this.isModal)
    }
  }

  render() {
    return (
      <OperationStart
        startWithRfidSearch={this.operation.startWithRfidSearch}
        submitPath={this.submitPath}
        extensions={ShippingExtensions}
        onSubmit={this.onSubmit}
      />
    )
  }
}
