import { Component } from 'react'
import {
  AntennaButton,
  Box,
  Button,
  Counter,
  FormSchemaTextField,
  KeyValueRow,
  Modal,
  Page,
  Spacer,
  Table,
} from 'stylewhere/components'
import { Router, OperationReadingProps, Storage, RfidReader, AppStore } from 'stylewhere/shared'
import styled from '@emotion/styled'
import { T, __ } from 'stylewhere/shared/i18n'
import { __isDev, askUserConfirmation, showToast, showToastError, sleep } from 'stylewhere/shared/utils'
import { InboundConveyorApi } from 'stylewhere/api/InboundConveyor'
import { InboundConveyor, TextField, TmrItem } from 'stylewhere/api/types'
import { Checkbox } from '@chakra-ui/react'
import { Items } from 'stylewhere/api'
import { CustomItemApi } from 'stylewhere/api/CustomItem'

type Printer = {
  value: string
  label: string
}

interface State {
  mission?: TextField
  warehouse?: TextField
  checkedRows: any[]
  inboundConveyor?: InboundConveyor
  printLayout: string
  goodItems: any[]
  embeddedItems: any[]
  ignoredItems: any[]
  showPrintModal: boolean
  selectedPrinter?: Printer
  printLoading: boolean
  confirmLoading: boolean
  printers?: Printer[]
}

export default class InboundConveyorScreen extends Component<OperationReadingProps<State>, State> {
  locationState = Router.getLocationState<State>(this.props)

  state: State = {
    showPrintModal: false,
    checkedRows: [],
    printLayout: 'ZV_ETICHETTE_MIS_RFID',
    printLoading: false,
    goodItems: [],
    embeddedItems: [],
    ignoredItems: [],
    confirmLoading: false,
    warehouse: { id: '1', code: 'V00' },
  }

  missionRef: any = null

  tableStructure = [
    {
      key: 'commessa',
      label: 'Commessa',
      customRender: (item, column) => (
        <Row center qty={item.qty} epcs={item.epcs} flex>
          {item.commessa}
        </Row>
      ),
      flex: 1,
    },
    {
      key: 'model',
      label: 'Modello',
      customRender: (item, column) => (
        <Row qty={item.qty} epcs={item.epcs} center flex>
          {item.material}
        </Row>
      ),
      flex: 1,
    },
    {
      key: 'size',
      label: 'Taglia/Colore',
      customRender: (item, column) => (
        <Row qty={item.qty} epcs={item.epcs} center flex>
          {item.sizeColor}
        </Row>
      ),
      flex: 1,
    },
    {
      key: 'wam',
      label: 'WAM',
      customRender: (item, column) => (
        <Row qty={item.qty} epcs={item.epcs} center flex>
          {item.wam}
        </Row>
      ),
      flex: 1,
    },
    {
      key: 'readQty',
      label: 'Qta Letta',
      flex: 1,
      customRender: (item, column) => (
        <Row qty={item.qty} epcs={item.epcs} center flex>
          {item.epcs.length}
        </Row>
      ),
    },
    {
      key: 'expectedQty',
      label: 'Qta Attesa',
      customRender: (item, column) => (
        <Row qty={item.qty} epcs={item.epcs} center flex>
          {item.qty}
        </Row>
      ),
      flex: 1,
    },
    {
      key: 'print',
      label: 'Stampa',
      customRender: (item, column) => {
        const isDisabled = item.qty <= item.epcs.length
        return (
          <Row center qty={item.qty} epcs={item.epcs} flex>
            <Checkbox
              isChecked={this.state.checkedRows.some((row) => row.id === item.id)}
              isDisabled={isDisabled}
              borderColor={'black'}
              onChange={(e) => {
                if (e.target.checked) {
                  this.setState({ checkedRows: [...this.state.checkedRows, item] })
                } else {
                  this.setState({ checkedRows: this.state.checkedRows.filter((row) => row.id !== item.id) })
                }
              }}
            />
          </Row>
        )
      },
      flex: 1,
    },
  ]

  async componentDidMount() {
    this.initReader()
    const printers = await this.fetchPrinters()
    const selectedPrinter = (await Storage.load('cq-selectedPrinter')) as Printer
    if (!selectedPrinter || !printers.find((printer) => printer.value === selectedPrinter.value)) return
    this.setState({ selectedPrinter })
  }

  fetchPrinters = async () => {
    try {
      const res = await InboundConveyorApi.getPrinters()
      if (!res || !res.content) throw new Error('No printers')
      const printers = res.content.map((printer: any) => ({ value: printer.code, label: printer.description }))
      this.setState({ printers })
      return printers
    } catch (error: any) {
      showToastError(error?.message ?? __(T.misc.error))
    }
  }

  clear = () => this.setState({})

  goBack = () => {
    Router.navigate('/')
  }

  onIngressoVarcoSearch = async () => {
    try {
      const { mission, warehouse } = this.state
      if (!mission || !mission.code || !warehouse || !warehouse.code)
        throw new Error('Missione o magazzino non selezionato')
      const inboundConveyor = await InboundConveyorApi.getInboundConveyor(mission?.code, warehouse?.code)
      inboundConveyor &&
        inboundConveyor.requestItems &&
        inboundConveyor.requestItems.map((item, index) => (item.id = index.toString()))
      this.setState({ inboundConveyor: inboundConveyor })
    } catch (error: any) {
      showToastError(error?.message ?? __(T.misc.error))
    }
  }

  initReader = async () => {
    RfidReader.setOnTagReadCallback((tag) => this.onTagReceived(tag.epc))
  }

  onTagReceived = async (tag: string) => {
    const { ignoredItems, goodItems, embeddedItems, inboundConveyor } = this.state

    try {
      /**** checking if is embedded tag */
      if (tag.startsWith('31')) {
        embeddedItems.push(tag)
        this.setState((prevState) => {
          const updatedEmbeddedItems = [...prevState.embeddedItems, tag]
          return { ...prevState, embeddedItems: updatedEmbeddedItems }
        })
        return
      }
      /************ */

      /*** item creation if the epc is not in the system but the product is retived by GS1 */
      if (!AppStore?.defaultWorkstation?.placeId)
        throw new Error('Postazione non selezionata in dashboard, selezionare una postazione per continuare')
      const items = (await CustomItemApi.batchDecode(
        [tag],
        AppStore?.defaultWorkstation?.placeId ?? AppStore.loggedUser?.place?.id ?? '',
        AppStore.loggedUser?.id ?? ''
      )) as { [tagCode: string]: TmrItem | null }
      if (!items) return
      /************ */

      const item = items[tag]

      if (!item || !item?.attributes || !item?.attributes?.wam || item?.identifiers?.length === 0) {
        ignoredItems.push({
          epc: tag,
        })
        return this.setState({
          ignoredItems,
        })
      }

      const itemFound = inboundConveyor?.requestItems.findIndex(
        (requestItem) => requestItem.wam === item.attributes!.wam
      )

      if (itemFound != undefined && itemFound > -1) {
        goodItems.push(item)
        inboundConveyor!.requestItems[itemFound].epcs.push(tag)
      } else {
        ignoredItems.push(item)
      }

      this.setState({ ignoredItems, goodItems, embeddedItems })
    } catch (error: any) {
      this.setState((prevState) => {
        return { ...prevState, ignoredItems: [...prevState.ignoredItems, { epc: tag }] }
      })
      showToastError(error.message)
    }
  }

  onPrinterSelected = (printer: Printer) => {
    this.setState({ selectedPrinter: printer })
    Storage.save('cq-selectedPrinter', printer)
  }

  printDocument = async () => {
    const { selectedPrinter, checkedRows, printLayout } = this.state
    try {
      this.setState({ printLoading: true })
      if (!selectedPrinter) throw new Error('No printer selected')

      const rowsToPrint = checkedRows.filter((row) => row.epcs.length < row.qty)

      await InboundConveyorApi.printInboundConveyor({
        printer: selectedPrinter?.value,
        layout: printLayout,
        wams: rowsToPrint.map((row) => ({
          commessa: row.commessa,
          material: row.material,
          sizeColor: row.sizeColor,
          stockCategory: row.stockCategory,
          wam: row.wam,
          qty: row.qty - (row?.epcs?.length ?? 0),
        })),
      })
      this.setState({ printLoading: false, showPrintModal: false, checkedRows: [] })
      showToast({
        status: 'success',
        title: __(T.misc.printing_started),
        sound: true,
      })
    } catch (error) {
      this.setState({ printLoading: false })
      showToast({ status: 'error', title: __(T.misc.select_printer) })
    }
  }

  clearReads = async () => {
    const { inboundConveyor } = this.state
    const res = await askUserConfirmation(__(T.misc.confirm), __(T.messages.are_you_sure_to_clear_reads))
    if (!res) return
    inboundConveyor?.requestItems.forEach((item) => (item.epcs = []))
    this.setState({ goodItems: [], embeddedItems: [], ignoredItems: [], checkedRows: [], inboundConveyor })
  }

  confirmInboundConveyor = async () => {
    try {
      const { inboundConveyor, mission, warehouse, goodItems } = this.state
      const itemRequestedQty = inboundConveyor?.requestItems.reduce((acc, item) => {
        acc += item.qty
        return acc
      }, 0)
      const missingItems = (Number(itemRequestedQty) ?? 0) - Number(goodItems.length)
      const missingMessage = __(T.messages.inbound_conveyor_confirm_missing_message).replace(
        '-missing-',
        missingItems.toString()
      )

      const confirmMessage = missingItems <= 0 ? __(T.messages.are_you_sure_to_confirm) : missingMessage

      const res = await askUserConfirmation(__(T.misc.confirm), confirmMessage)

      if (!res || !inboundConveyor) return
      this.setState({ confirmLoading: true })
      const result = await InboundConveyorApi.confirmInboundConveyor({
        ...inboundConveyor,
        mission: mission?.code ?? '',
        warehouse: warehouse?.code ?? 'V00',
      })

      this.resetData()

      showToast({
        status: 'success',
        title: __(T.misc.success),
        sound: true,
      })
    } catch (error: any) {
      console.log('confirmInboundConveyor Error', error)
      this.setState({ confirmLoading: false })
      showToast({
        status: 'error',
        title: error?.message ?? __(T.misc.error),
        sound: true,
      })
    }
  }

  resetData = () => {
    this.setState({
      mission: { id: '1', code: '' },
      warehouse: { id: '1', code: 'V00' },
      checkedRows: [],
      inboundConveyor: undefined,
      printLayout: 'ZV_ETICHETTE_MIS_RFID',
      goodItems: [],
      embeddedItems: [],
      ignoredItems: [],
      showPrintModal: false,
      printLoading: false,
      confirmLoading: false,
    })
  }

  render() {
    const {
      mission,
      inboundConveyor,
      embeddedItems,
      goodItems,
      ignoredItems,
      showPrintModal,
      selectedPrinter,
      checkedRows,
      printLoading,
      confirmLoading,
      warehouse,
      printers,
    } = this.state
    const hideClear = (embeddedItems?.length ?? 0) + (goodItems?.length ?? 0) + (ignoredItems?.length ?? 0) === 0

    const tipo = inboundConveyor?.tipoStock ?? ''
    const supplier = inboundConveyor?.lifNr ?? ''
    const physicalStorage = warehouse?.code ?? ''
    const season = inboundConveyor?.trilogySeason ?? ''
    const ddt = inboundConveyor?.ddt ?? ''
    const ddtDate = inboundConveyor?.dataDdt ?? ''
    const notification = inboundConveyor?.numNotifica ?? ''
    const notificationDate = inboundConveyor?.dataNotifica ?? ''

    const itemRequestedQty = inboundConveyor?.requestItems.reduce((acc, item) => {
      acc += item.qty
      return acc
    }, 0)

    const missingItems = Number(itemRequestedQty ?? 0) - Number(goodItems.length ?? 0)

    return (
      <Page title={'Ingresso Varco CQ'} enableEmulation>
        <Page.Content notBoxed>
          <InfoContainer>
            <FormContainer>
              <FormColumn>
                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: __(T.fields.mission), name: 'Mission', focus: true }}
                  index={0}
                  value={mission?.code}
                  onChange={(text: string) => {
                    const isClear = text === '' || text === undefined
                    this.setState({
                      mission: { ...mission, id: '3', code: text },
                      inboundConveyor: isClear ? undefined : inboundConveyor,
                    })
                    if (isClear) this.resetData()
                  }}
                  onEnter={this.onIngressoVarcoSearch}
                />

                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: __(T.fields.mission_type), name: 'MissionType' }}
                  index={1}
                  value={tipo}
                  disabled
                  onChange={(text: string) => {
                    console.log('onChange Mission Type', text)
                  }}
                />

                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: __(T.fields.supplier), name: 'Supplier' }}
                  index={2}
                  value={supplier}
                  disabled
                  onChange={() => {}}
                />
              </FormColumn>
              <FormColumn>
                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: __(T.fields.physical_storage), name: 'PhysicalStorage' }}
                  index={3}
                  value={physicalStorage}
                  disabled
                  onChange={(text: string) => {
                    this.setState((prevState) => {
                      return {
                        warehouse: { ...prevState.warehouse, code: text, id: prevState?.warehouse?.id || '' },
                      }
                    })
                  }}
                />

                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: __(T.fields.season), name: 'Season' }}
                  index={4}
                  disabled
                  value={season}
                  onChange={() => {}}
                />
              </FormColumn>
              <FormColumn>
                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: __(T.fields.ddt), name: 'Ddt' }}
                  index={5}
                  disabled
                  value={ddt}
                  onChange={() => {}}
                />

                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: __(T.fields.ddt_date), name: 'DDtDate' }}
                  index={5}
                  disabled
                  value={ddtDate}
                  onChange={() => {}}
                />
              </FormColumn>
              <FormColumn>
                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: __(T.fields.notification), name: 'Notification' }}
                  index={4}
                  disabled
                  value={notification}
                  onChange={() => {}}
                />

                <FormSchemaTextField
                  defaultValue={undefined}
                  field={{ type: 'text', label: __(T.fields.notification_date), name: 'NotificationDate' }}
                  index={4}
                  disabled
                  value={notificationDate}
                  onChange={() => {}}
                />
              </FormColumn>
            </FormContainer>
            <Box center>
              <AntennaButton
                disabled={!inboundConveyor}
                style={{ width: 400 }}
                hideClear={hideClear}
                showPendingTags={false}
                onClear={() => this.clearReads()}
              />
            </Box>
          </InfoContainer>
          <Spacer height={'30'} />
          <InfoContainer>
            <Box row vcenter center>
              <Button
                variant="secondary"
                disabled={checkedRows.length === 0 || !inboundConveyor}
                title={__(T.misc.print)}
                onClick={() => this.setState({ showPrintModal: true })}
              />
              <Spacer width={10} />
              <Button
                disabled={!inboundConveyor || goodItems.length === 0}
                loading={confirmLoading}
                title={__(T.misc.confirm)}
                onClick={() => this.confirmInboundConveyor()}
              />
            </Box>
            <Spacer height={'20'} />
            <Box
              row
              vcenter
              center
              flexWrap
              style={{
                gap: 10,
              }}
            >
              <Counter bgColor="#1fd655" count={goodItems?.length ?? 0} text={__(T.misc.ok)} />
              <Counter bgColor="#F09235" count={missingItems ?? 0} text={__(T.misc.missings)} />
              <Counter bgColor="#F6C344" count={embeddedItems?.length ?? 0} text={__(T.misc.embedded)} />
              <Counter bgColor="#CCCCCC" count={ignoredItems?.length ?? 0} text={__(T.misc.ignoreds)} />
            </Box>
            <Spacer width={20} />
            <Table
              data={inboundConveyor?.requestItems as any}
              structure={this.tableStructure}
              bodyCellStyle={{
                margin: 0,
                padding: 0,
              }}
            />
          </InfoContainer>
        </Page.Content>
        <Modal
          closeOnOverlayClick={!printLoading}
          visible={showPrintModal}
          onClose={() =>
            this.setState({
              showPrintModal: false,
            })
          }
        >
          <Box>
            <Title>{__(T.misc.print)}</Title>
          </Box>
          <Box pt={20}>
            <KeyValueRow
              label={__(T.misc.select_printer)}
              style={{ width: '100%' }}
              value={selectedPrinter?.label}
              optionModal={{
                onSelect: (printer) => this.onPrinterSelected(printer[0]),
                field: 'label',
                options: printers || [],
                selected: [selectedPrinter],
                title: __(T.titles.select_printer),
                searchable: true,
              }}
            />
            <Spacer height={'20'} />
            <Box row vcenter center>
              <Button
                variant="secondary"
                disabled={printLoading}
                title={__(T.misc.cancel)}
                onClick={() => this.setState({ showPrintModal: false })}
              />
              <Spacer width={10} />
              <Button
                loading={printLoading}
                disabled={!selectedPrinter}
                title={__(T.misc.confirm)}
                onClick={this.printDocument}
              />
            </Box>
          </Box>
        </Modal>
      </Page>
    )
  }
}

//generate row with epcs and qty prop
const Row = styled(Box)<{ epcs: string[]; qty: number }>`
  background-color: ${({ epcs, qty }) => (epcs.length === qty ? '#1fd655' : epcs.length > 0 ? 'red' : 'transparent')};
  font-weight: bold;
`

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px; // Questo aggiunge uno spazio sia orizzontale che verticale tra gli elementi
  justify-content: flex-start; // Modificato da space-between a flex-start per evitare che gli elementi si estendano
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  background-color: 'red';
`

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; // Mantiene lo spazio tra gli elementi verticali
  flex: 1; // Questo permette alle colonne di riempire lo spazio disponibile
  min-width: 200px; // Assegna una larghezza minima per la colonna considerando il gap
  box-sizing: border-box; // Assicura che il padding e il bordo siano inclusi nella larghezza
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
`

const Title = styled.span`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
`

const Description = styled.label`
  font-weight: 500;
  padding: 25px 15px;
  font-size: 21px;
  text-align: center;
  opacity: 0.5;
`
