import { merge as _merge } from 'lodash'
import it from 'core/translations/it'

export default _merge(it, {
  titles: {
    outbound_bthp: 'Outbound Certificato BTHP',
    sorting_details: 'Dettagli ventilazione',
  },
  messages: {
    are_you_sure_to_clear_reads: 'Sei sicuro di voler cancellare le letture?',
    are_you_sure_to_confirm: 'Sei sicuro di voler confermare?',
    inbound_conveyor_confirm_missing_message:
      'Mancano ancora -missing- articoli da confermare, sei sicuro di voler confermare?',
  },
  simple_parcel_list: {
    to_read: 'Non letto',
    ok: 'Ok',
    force: 'Forza',
    force_parcel: 'Forza collo {{code}}',
    force_parcel_desc: 'Inserisci il PIN utente per forzare il collo',
    forced: 'Forzato',
    unexpected: 'Inatteso',
    remove: 'Rimuovi',
    invalid_pin: 'PIN utente non valido',
  },
  create_parcel_gate: {
    width: 'Larghezza',
    height: 'Altezza',
    depth: 'Profondità',
    tare: 'Tara',
    description: 'Descrizione',
  },
  misc: {
    sorted_quantity: 'Qt ventilata',
    sorted_date: 'Data ventilazione',
    error: 'Errore',
    OK_WORKER: 'OK FORNITORE',
    KO_WORKER: 'KO FORNITORE',
    OK_REPAIR: 'OK RIPARAZIONE',
    OK_VALDAGNO: 'OK VALDAGNO',
    KO_VALDAGNO: 'KO VALDAGNO',
    RESO_RICHIESTO_CQ: 'RICHIESTO CQ',
    RESO_OK: 'RESO OK',
    SECOND_VALDAGNO: 'SECONDA SCELTA',
    OK_LINING: 'OK FODERA',
    OK_RISC: 'RISCHIATO',
    KO_LINING: 'KO FODERA',
    size_color: 'Taglia/Colore',
    select_size_color: 'Seleziona taglia/colore',
  },
  error: {
    sorting_already_read: 'Articolo con seriale {{serial}} già ventilato nel gruppo {{group}}',
    sorting_no_group: 'Articolo da non ventilare',
    sorting_not_in_zone: "Articolo con seriale {{serial}} da non ventilare. L'articolo è nella zona {{zone}}",
  },
  serverError: {
    WRONG_ITEM_SUPPLIER: 'Rilevati item non associati al fornitore dichiarato.',
    QUANTITY_MISMATCH_ERROR: 'Quantità inserite non corrispondenti alle quantità rilevate.',
    ZONE_MISMATCH_ERROR: 'Zona inserita non corrispondente a quella rilevata.',
  },
  custom: {
    barcode: 'Barcode BC',
    barcodeBCTrim: 'Barcode BC trim',
    barcodeJDE: 'Barcode JDE',
    barcodeROQ: 'Barcode ROQ',
    barcodeROQTrim: 'Barcode ROQ trim',
    class: 'Classe',
    department: 'Dipartimento',
    dimensionSystem: 'Classe personalizzata',
    ean: 'EAN',
    eanIdPadre: 'EAN id padre',
    itemId: 'Item Id',
    stagioneROQ: 'Stagione ROQ',
    subclass: 'Sottoclasse',
    subdepartment: 'Sottodipartimento',
    variantType: 'Tipologia',
    rfidEnabled: 'RFID abilitato',
    stato: 'Stato',
    ricondizionamentoFornitore: 'Fornitore ricondizionamento',
    codiceShelf: 'Codice scaffale',
    montante: 'Montante',
    ripiano: 'Ripiano',
    scaffale: 'Scaffale',
    rejected: 'Scartati',
    tot_to_check: 'Tot. da riscontrare',
  },
  attributes: {
    material: 'Materiale',
    workerCode: 'Codice Fornitore',
    workerExternalCode: 'Codice Fornitore Esterno',
    workerIdentifier: 'Identificativo Fornitore',
    workerDescription: 'Descrizione Fornitore',
    workerType: 'Tipo Fornitore',
    orderCode: 'Codice Ordine',
    sapQualityControlStatus: 'Stato Controllo Qualità SAP',
    season: 'Stagione',
    mode: 'Modalità',
    sizeColor: 'Taglia/Colore',
    quantityOkWorker: 'OK FORNITORE QTY',
    quantityKoWorker: 'KO FORNITORE QTY',
    quantityOkRepair: 'OK RIPARAZIONE QTY',
    quantityOkValdagno: 'OK VALDAGNO QTY',
    quantityOkFirstInbound: 'OK PRIMO INGRESSO QTY',
    quantityOkRis: 'RISCHIATO QTY',
    quantityKoValdagno: 'KO VALDAGNO QTY',
    quantitySecondValdagno: 'SECONDA SCELTA QTY',
    quantityOkLining: 'OK FODERA QTY',
    quantityKoLining: 'KO FODERA QTY',
  },
})
