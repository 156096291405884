import { set } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { InboundWorkstationApi, api } from 'stylewhere/api'
import { InboundWorkstationReportData, SapInbound, SapInboundUpdatePayload } from 'stylewhere/api/types'
import {
  Box,
  Button,
  FormSchemaNumberField,
  FormSchemaTextField,
  FullLoadingLayer,
  Modal,
  Spacer,
  Table,
} from 'stylewhere/components'
import { config } from 'stylewhere/config'
import { AppStore } from 'stylewhere/shared'
import { T, __ } from 'stylewhere/shared/i18n'
import { askUserConfirmation, showToast, showToastError, sleep } from 'stylewhere/shared/utils'

export interface NoteModalWrapper {
  id: string
  loading: boolean
  confirmed: boolean
  data: SapInbound
  changed: boolean
}

export interface NoteModalRow {
  id: string
  code?: string
  sizeAndColor?: string
  style?: string
  damageTotal?: string
  totalItems?: string
  mailNote?: string
  generalNote?: string
  totalReturned?: string
  totalChecked?: string
  confirmed?: boolean
}

interface Props {
  visible: boolean
  onClose: () => void
  onRowPartialConfirmationDone: (row: NoteModalRow) => Promise<void>
  rows: SapInbound[]
  refreshData: () => Promise<void>
  refreshWorkstationData: () => Promise<void>
  setShowPageOverlayLoader: (show: boolean) => void
}

export default function InboundWorkstationNoteModal({
  rows,
  onClose,
  visible,
  onRowPartialConfirmationDone,
  refreshData,
  refreshWorkstationData,
  setShowPageOverlayLoader,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [rowsState, setRowsState] = useState<NoteModalWrapper[]>(
    rows.map((row: SapInbound) => ({
      data: { ...row },
      id: row.id,
      confirmed: !!row.confirmationDate ?? false,
      loading: false,
      changed: false,
    }))
  )

  const evtSource = useRef<EventSource | null>(null)

  useEffect(() => {
    setRowsState(
      rows.map((row) => ({
        data: { ...row },
        id: row.id,
        confirmed: !!row.confirmationDate ?? false,
        loading: false,
        changed: false,
      }))
    )
  }, [rows])

  const tableStructure = [
    {
      key: 'code',
      label: 'Commessa',
      customRender: (item: NoteModalWrapper, column) => <Box>{item.data.commessa}</Box>,
      width: 120,
    },
    {
      key: 'sizeAndColor',
      label: 'Taglia/Colore',
      customRender: (item: NoteModalWrapper, column) => <Box>{item.data.j3ASIZE}</Box>,
      width: 100,
    },
    {
      key: 'style',
      label: 'Modello',
      customRender: (item: NoteModalWrapper, column) => <Box>{item.data.matNr}</Box>,
      width: 120,
    },
    {
      key: 'damageTotal',
      label: 'Totale Difetti',
      customRender: (item: NoteModalWrapper, column) => <Box>{item.data.totalDefected}</Box>,
      width: 100,
    },
    {
      key: 'totalItems',
      label: 'Totale Capi',
      customRender: (item: NoteModalWrapper, column) => <Box>{item.data.totalQty}</Box>,
      width: 100,
    },
    {
      key: 'mailNote',
      label: 'Note Mail',
      value: 'mailNote',
      customRender: (item: NoteModalWrapper, column) => (
        <FormSchemaTextField
          defaultValue={undefined}
          field={{ type: 'text', label: '', name: 'mailNotes', focus: false }}
          index={1}
          disabled={item.loading}
          value={item.data.mailNote}
          onChange={(text: string) => {
            setRowsState((prev) =>
              prev.map((row) =>
                row.data.id === item.id
                  ? {
                      data: { ...row.data, mailNote: text },
                      id: row.id,
                      loading: false,
                      confirmed: false,
                      changed: true,
                    }
                  : row
              )
            )
          }}
        />
      ),
      flex: 1,
    },
    {
      key: 'generalNote',
      label: 'Note generiche',
      customRender: (item: NoteModalWrapper, column) => (
        <FormSchemaTextField
          defaultValue={undefined}
          field={{ type: 'text', label: '', name: 'generalNote', focus: false }}
          index={1}
          disabled={item.loading}
          value={item.data.genericNote}
          onChange={(text: string) => {
            setRowsState((prev) =>
              prev.map((row) =>
                row.data.id === item.id
                  ? {
                      data: { ...row.data, genericNote: text },
                      id: row.id,
                      loading: false,
                      confirmed: false,
                      changed: true,
                    }
                  : row
              )
            )
          }}
        />
      ),
      flex: 1,
    },
    {
      key: 'totalReturned',
      label: 'Totale Rientrati',
      customRender: (item: NoteModalWrapper, column) => (
        <FormSchemaNumberField
          defaultValue={undefined}
          field={{ type: 'number', label: '', name: 'Totale Rientrati', focus: false, max: item.data.totalQty, min: 0 }}
          index={0}
          value={item.data.totalReturn ?? 0}
          disabled={item.loading}
          onChange={(text: string) => {
            setRowsState((prev) =>
              prev.map((row) =>
                row.data.id === item.id
                  ? {
                      data: { ...row.data, totalReturn: Number(text) },
                      id: row.id,
                      loading: false,
                      confirmed: false,
                      changed: true,
                    }
                  : row
              )
            )
          }}
        />
      ),
      width: 150,
    },
    {
      key: 'totalChecked',
      label: 'Totale Collaudati',
      customRender: (item: NoteModalWrapper, column) => (
        <FormSchemaNumberField
          defaultValue={undefined}
          field={{ type: 'number', label: '', name: 'totalChecked', focus: false, max: item.data.totalQty, min: 0 }}
          index={1}
          disabled={item.loading || !!item.data.confirmationDate}
          value={item.data.totalCq ?? 0}
          onChange={(text: string) => {
            setRowsState((prev) =>
              prev.map((row) =>
                row.data.id === item.id
                  ? {
                      data: { ...row.data, totalCq: Number(text) },
                      id: row.id,
                      loading: false,
                      confirmed: false,
                      changed: true,
                    }
                  : row
              )
            )
          }}
        />
      ),
      width: 150,
    },
    {
      key: 'actions',
      label: 'Azioni',
      customRender: (item: NoteModalWrapper, column) => (
        <Box center>
          <Button
            loading={item.loading}
            disabled={!!item.data.confirmationDate || item.confirmed || item.changed}
            title={__(T.misc.confirm)}
            style={{ fontSize: 13, height: 30 }}
            onClick={() => confirmRow(item)}
          />
        </Box>
      ),
      width: 100,
    },
  ]

  const confirmRow = async (row: NoteModalWrapper) => {
    try {
      setLoading(true)
      setShowPageOverlayLoader(true)
      const { totalCq, totalQty, totalReturn } = row.data

      if (totalCq === undefined || totalQty === undefined || totalReturn === undefined)
        throw new Error('Inserire valori validi per Totale Collaudati e Totale Capi e Totale Rientrati')

      if (totalQty < totalCq) throw new Error('Totale Collaudati non può essere maggiore di Totale Capi')
      if (totalReturn > totalQty) throw new Error('Totale Rientrati non può essere maggiore di Totale Capi')

      let message = ''
      if (totalReturn !== totalCq)
        message = `Attenzione: Totale Collaudati e Totale Rientrati non coincidono. Si desidera confermare comunque?`
      if (totalQty !== totalCq)
        message = `Attenzione: Totale Collaudati e Totale Capi non coincidono. Si desidera confermare comunque?`

      if (!!message && message !== '') {
        const res = await askUserConfirmation(message, 'Conferma', 'No', 'Si')
        setLoading(false)
        if (!res) return
      }

      setRowsState((prev) => prev.map((r) => (r.data.id === row.id ? { ...r, loading: true, changed: false } : r)))

      await InboundWorkstationApi.partialSapInboundConfirm({
        id: row.data.id,
        mission: row.data.mission,
        warehouse: row.data.warehouse ?? 'V00',
        commessa: row.data.commessa,
        matNr: row.data.matNr ?? '',
      })

      evtSource.current = new EventSource(
        config.endpoint +
          `api/v1/sse/getData/sapInboundPostazioneConfirm/partial_${AppStore.loggedUser?.username ?? '---'}_${
            row.data.id
          }?access_token=${AppStore.authToken}`,
        { withCredentials: false }
      )

      evtSource.current.onerror = async (event) => {
        console.log('error', event)
        if (!evtSource.current) return
        evtSource.current.close()
        setShowPageOverlayLoader(false)
        setLoading(false)
        setRowsState((prev) =>
          prev.map((r) => (r.data.id === row.id ? { ...r, confirmed: false, loading: false, changed: false } : r))
        )
        showToastError('Errore durante la conferma della riga')
      }

      evtSource.current.onmessage = async (event) => {
        console.log('onmessage', event)
        if (!evtSource.current) return
        const data: any = JSON.parse(event.data)
        if (data?.data?.success) {
          evtSource.current.close()
          await onRowPartialConfirmationDone(row)
          setLoading(false)
          setShowPageOverlayLoader(false)
          showToast({
            status: 'success',
            title: 'Confermato',
          })
          await refreshData()
        } else {
          setShowPageOverlayLoader(false)
          setLoading(false)
          setRowsState((prev) =>
            prev.map((r) => (r.data.id === row.id ? { ...r, confirmed: false, loading: false, changed: false } : r))
          )
          showToastError('Errore durante la conferma della riga')
        }
      }

      // setRowsState((prev) =>
      //   prev.map((r) => (r.data.id === row.id ? { ...r, confirmed: true, loading: false, changed: false } : r))
      // )
      // showToast({
      //   status: 'success',
      //   title: 'Confermato',
      // })
      // await onRowPartialConfirmationDone(row)
      //await refreshData()
    } catch (error: any) {
      if (evtSource.current) evtSource.current.close()
      setShowPageOverlayLoader(false)
      setLoading(false)
      setRowsState((prev) =>
        prev.map((r) => (r.data.id === row.id ? { ...r, confirmed: false, loading: false, changed: false } : r))
      )
      showToastError(error.message ?? 'Errore generico')
    }
  }

  const saveData = async () => {
    try {
      const rowsData = rowsState.map((row) => row.data)

      rowsData.forEach((row) => {
        if (row.totalCq === undefined || row.totalQty === undefined || row.totalReturn === undefined)
          throw new Error('Inserire valori validi per Totale Collaudati e Totale Capi e Totale Rientrati')
        if (row.totalReturn > row.totalQty) throw new Error('Totale Rientrati non può essere maggiore di Totale Capi')
        if (row.totalCq > row.totalQty) throw new Error('Totale Collaudati non può essere maggiore di Totale Capi')
      })

      setLoading(true)
      const rowsToSave: SapInboundUpdatePayload = {
        noteUpdates: rowsData.map((row) => {
          return {
            genericNote: row.genericNote ?? '',
            mailNote: row.mailNote ?? '',
            mission: row.mission,
            totalReturn: Number(row.totalReturn),
            id: row.id,
            totalCq: Number(row.totalCq),
            totalQty: Number(row.totalQty),
            warehouse: row.warehouse ?? 'V00',
          }
        }),
      }
      await InboundWorkstationApi.updateSapInbound(rowsToSave)
      setRowsState(rowsState.map((row) => ({ ...row, changed: false })))
      await refreshWorkstationData()
      setLoading(false)
      showToast({
        status: 'success',
        title: __(T.misc.success),
      })
    } catch (error: any) {
      setLoading(false)
      showToastError(error.message ?? 'Errore generico')
    }
  }

  return (
    <Modal onClose={onClose} visible={visible} size={'full'}>
      {loading && <FullLoadingLayer />}
      {!loading && (
        <Box center>
          <Table style={{ flex: 1 }} data={rowsState} structure={tableStructure} />
          <Spacer height="20" />
          <Box row>
            <Button variant="secondary" title={__(T.misc.close)} onClick={onClose} />
            <Spacer width={20} />
            <Button variant="primary" title={__(T.misc.save)} onClick={saveData} />
          </Box>
        </Box>
      )}
    </Modal>
  )
}
